// console.log(process.env)

import Vue from 'vue';
import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  IconsPlugin,
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import excel from 'vue-excel-export';

import router from './router';
import store from './store';
import App from './App.vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

Vue.use(excel);

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'v', // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
});

import swal from 'sweetalert2';
window.Swal = swal;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
