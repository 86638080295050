import Vue from "vue";
import VueRouter from "vue-router";
// localStorage.removeItem('username');
// localStorage.removeItem('storedData');
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/homemain.vue"),
      meta: {
        breadcrumb: [
          {
            text: "หน้าหลัก",
            active: true,
          },
        ],
      },
    },

    {
      path: "/accept-material",
      name: "inpuwassadu",
      component: () => import(/* webpackChunkName: "testOneView" */ "@/views/wasadu/accept-material.vue"),
      meta: {
        requiresAuth: false,
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "รับเข้าวัสดุ",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/material",
      name: "rowwasadu",
      component: () => import("@/views/wasadu/material.vue"),
      meta: {
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "วัสดุคงคลัง",
            active: true,
          },
        ],
      },
    },
    {
      path: "/material/summary-of-the-receipt",
      name: "inrowwasadu",
      props: true,
      component: () => import("@/views/wasadu/summary-of-the-receipt.vue"),
      meta: {
        navActiveLink: "rowwasadu",
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "วัสดุคงคลัง",
            to: {
              name: "rowwasadu",
              children: {
                active: true,
              },
            },
          },
          {
            text: "สรุปการรับเข้าเบิกจ่ายคงเหลือวัสดุ",
            active: true,
          },
        ],
      },
    },

    {
      path: "/pick-up-material",
      name: "outputwassadu",
      component: () => import("@/views/wasadu/pick-up-material.vue"),
      meta: {
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "เบิกวัสดุ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/search-for-materials",
      name: "seawassadu",
      component: () => import("@/views/wasadu/search-for-materials.vue"),
      meta: {
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "ค้นหาวัสดุ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report_wassadu",
      name: "report_wassadu",
      component: () => import("@/views/wasadu/report_wassadu.vue"),
      meta: {
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "รายงานกลุ่มวัสดุ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report_wassadu_workgroup",
      name: "report_wassadu_workgroup",
      component: () => import("@/views/wasadu/report_wassadu_workgroup.vue"),
      meta: {
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "รายงานกลุ่มงาน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/search-for-materials/incoming-material-details",
      name: "inseawassadu",
      props: true,
      component: () => import("@/views/wasadu/incoming-material-details.vue"),
      meta: {
        navActiveLink: "seawassadu",
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "ค้นหาวัสดุ",
            to: { name: "seawassadu" },
          },
          {
            text: "รายละเอียดวัสดุรับเข้า",
            active: true,
          },
        ],
      },
    },

    {
      path: "/pick-up-material/material-picking-list",
      name: "detallin",
      props: true,
      component: () => import("@/views/wasadu/material-picking-list.vue"),
      meta: {
        navActiveLink: "outputwassadu",
        breadcrumb: [
          {
            text: "วัสดุ",
          },
          {
            text: "เบิกวัสดุ",
            to: { name: "outputwassadu" },
          },
          {
            text: "รายการเบิกวัสดุ",
            active: true,
          },
        ],
      },
    },

    //ทรัพย์สิน
    {
      path: "/receive-equipment",
      name: "inputkuruplun",
      component: () => import("@/views/kuruplun/receive-equipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รับเข้าทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/receive-equipment/equipment-list",
      name: "in-inputkuruplun",
      props: true,
      component: () => import("@/views/kuruplun/equipment-list.vue"),
      meta: {
        navActiveLink: "inputkuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รับเข้าทรัพย์สินสิน",
            to: { name: "inputkuruplun" },
          },
          {
            text: "รายการลงทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/find-equipment/admission-details/cost-of-equipment",
      name: "indepreciation",
      component: () => import("@/views/kuruplun/cost-of-equipment.vue"),
      meta: {
        navActiveLink: "seakuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ค้นหาทรัพย์สินสิน",
            to: { name: "seakuruplun" },
          },
          {
            text: "รายละเอียดทรัพย์สินรับเข้า",
          },
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report_kuruplun",
      name: "report_kuruplun",
      component: () => import("@/views/kuruplun/report_kuruplun.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },

          {
            text: "รายงานกลุ่มทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/report_kuruplun_workgroup",
      name: "report_kuruplun_workgroup",
      component: () => import("@/views/kuruplun/report_kuruplun_workgroup.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },

          {
            text: "รายงานกลุ่มงาน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/waiting-for-pick-up",
      name: "waitingkuruplun",
      component: () => import("@/views/kuruplun/waiting-for-pick-up.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ทรัพย์สินสินคงคลัง",
            active: true,
          },
        ],
      },
    },
    {
      path: "/inventory",
      name: "inventory",
      component: () => import("@/views/kuruplun/inventory.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ทรัพย์สินคงคลัง",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pick-up-equipment",
      name: "outputkuruplun",
      component: () => import("@/views/kuruplun/pick-up-equipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "เบิกทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/borrow-equipment",
      name: "borrowkuruplun",
      component: () => import("@/views/kuruplun/borrow-equipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ยืมทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/return-equipment",
      name: "returnkuruplun",
      component: () => import("@/views/kuruplun/return-equipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ส่งคืนทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sellkuruplun",
      name: "sellkuruplun",
      component: () => import("@/views/kuruplun/sellkuruplun.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ทรัพย์สินจำหน่าย",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tablekuruplun",
      name: "tablekuruplun",
      component: () => import("@/views/kuruplun/tablekuruplun.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รายการทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/find-equipment",
      name: "seakuruplun",
      component: () => import("@/views/kuruplun/find-equipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ค้นหาทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },

    {
      path: "/waiting-for-pick-up/equipment-for-list",
      name: "tableinkuruplun",
      props: true,
      component: () => import("@/views/kuruplun/equipment-for-list.vue"),
      meta: {
        navActiveLink: "waitingkuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ทรัพย์สินสินคงคลัง",
            to: { name: "waitingkuruplun" },
          },
          {
            text: "รายการทรัพย์สินสินคงคลัง",
            active: true,
          },
        ],
      },
    },
    {
      path: "/waitingequipment",
      name: "waitingequipment",
      component: () => import("@/views/kuruplun/waitingequipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รอเบิกทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/returnequipment",
      name: "returnequipment",
      component: () => import("@/views/kuruplun/returnequipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รอคืนทรัพย์สิน",
            active: true,
          },
        ],
      },
    },

    {
      path: "/inreturnequipment",
      name: "inreturnequipment",
      component: () => import("@/views/kuruplun/inreturnequipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รายการรอคืนทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/inwaitingequipment",
      name: "inwaitingequipment",
      component: () => import("@/views/kuruplun/inwaitingequipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รายการคงคลังทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tusinputkuruplun",
      name: "tusinputkuruplun",
      component: () => import("@/views/kuruplun/tusinputkuruplun.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "รายการทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/find-equipment/admission-details/:id",
      name: "inseakuruplun",
      component: () => import("@/views/kuruplun/admission-details.vue"),
      meta: {
        navActiveLink: "seakuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ค้นหาทรัพย์สินสิน",
            to: { name: "seakuruplun" },
          },
          {
            text: "รายละเอียดทรัพย์สินรับเข้า",
            active: true,
          },
        ],
      },
    },
    {
      path: "/pick-up-equipment/list-of-equipment",
      name: "inoutputkuruplun",
      props: true,
      component: () => import("@/views/kuruplun/list-of-equipment.vue"),
      meta: {
        navActiveLink: "outputkuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "เบิกทรัพย์สินสิน",
            to: { name: "outputkuruplun" },
          },
          {
            text: "รายการเบิกทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/borrow-equipment/list-of-borrowed-equipment",
      name: "inborrowkuruplun",
      props: true,
      component: () => import("@/views/kuruplun/list-of-borrowed-equipment.vue"),
      meta: {
        navActiveLink: "borrowkuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ยืมทรัพย์สินสิน",
            to: { name: "borrowkuruplun" },
          },
          {
            text: "รายการยืมทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/return-equipment/return-equipment-list",
      name: "inreturnkuruplun",
      props: true,
      component: () => import("@/views/kuruplun/return-equipment-list.vue"),
      meta: {
        navActiveLink: "returnkuruplun",
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ส่งคืนทรัพย์สินสิน",
            to: { name: "returnkuruplun" },
          },
          {
            text: "รายการส่งคืนทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    //ทรัพย์สิน

    //ทรัพย์สินสิน
    {
      path: "/depreciation",
      name: "deterioration",
      component: () => import("@/views/registration/deterioration.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ประมวลผลค่าเสื่อมประจำปี",
            active: true,
          },
        ],
      },
    },
    {
      path: "/counting",
      name: "counting",
      component: () => import("@/views/registration/counting.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ตรวจนับทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/counting_neits",
      name: "counting_neits",
      component: () => import("@/views/registration/counting_neits.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน สทศ",
          },
          {
            text: "ตรวจนับทรัพย์สิน สทศ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/counting_neits_in",
      name: "counting_neits_in",
      component: () => import("@/views/registration/counting_neits_in.vue"),
      meta: {
        navActiveLink: "counting_neits",
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน สทศ",
          },
          {
            text: "ตรวจนับทรัพย์สิน สทศ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/selling",
      name: "selling",
      component: () => import("@/views/registration/selling.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
          },
          {
            text: "ทะเบียนจำหน่ายทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/rehearsal",
      name: "rehearsal",
      component: () => import("@/views/registration/rehearsal.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ประวัติการแจ้งซ่อม",
            active: true,
          },
        ],
      },
    },
    {
      path: "/selling/selling-property-registrations/:id",
      name: "sselling",
      component: () => import("@/views/registration/selling-property-registrations.vue"),
      meta: {
        navActiveLink: "selling",
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ทะเบียนจำหน่ายทรัพย์สิน",
            to: { name: "selling" },
          },
          {
            text: "รายการจำหน่ายจากทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },

    {
      path: "/handover",
      name: "handover",
      component: () => import("@/views/registration/handover.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/personnel",
      name: "personnel",
      component: () => import("@/views/registration/personnel.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทรัพย์สินสิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/annual-check",
      name: "annualcheck",
      component: () => import("@/views/registration/annual-check.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ตรวจสอบประจำปี",
            active: true,
          },
        ],
      },
    },
    {
      path: "/annual-check-neits",
      name: "annualcheck_neits",
      component: () => import("@/views/registration/annual-check-neits.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน สทศ",
          },
          {
            text: "ตรวจสอบประจำปี สทศ",
            active: true,
          },
        ],
      },
    },

    {
      path: "/annual-check-neits/annual-equipment-neits/:id",
      name: "inannualcheck2_neits",
      component: () => import("@/views/registration/annual-equipment-neits.vue"),
      meta: {
        navActiveLink: "annualcheck_neits",
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน สทศ",
          },
          {
            text: "ตรวจสอบประจำปี สทศ",
            to: { name: "annualcheck_neits" },
          },
          {
            text: "สำรวจทรัพย์สินประจำปี สทศ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/depreciation/depreciation-list/:id",
      name: "inannualcheck",
      component: () => import("@/views/registration/depreciation-list.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ประมวลผลค่าเสื่อมประจำปี",
            to: { name: "deterioration" },
          },
          {
            text: "รายการตรวจสอบประจำปี",
            active: true,
          },
        ],
      },
    },
    {
      path: "/annual-check/annual-equipment/:id",
      name: "inannualcheck2",
      component: () => import("@/views/registration/annual-equipment.vue"),
      meta: {
        navActiveLink: "annualcheck",
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ตรวจสอบประจำปี",
            to: { name: "annualcheck" },
          },
          {
            text: "สำรวจทรัพย์สินประจำปี",
            active: true,
          },
        ],
      },
    },
    {
      path: "/indeterioration",
      name: "indeterioration",
      component: () => import("@/views/registration/indeterioration.vue"),
      meta: {
        breadcrumb: [
          {
            text: "รายการประมวลผลค่าเสื่อมประจำปี",
            active: true,
          },
        ],
      },
    },
    {
      path: "/counting/counting-check/:id",
      name: "incounting",
      component: () => import("@/views/registration/counting-check.vue"),
      meta: {
        navActiveLink: "counting",
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ตรวจนับทรัพย์สิน",
            to: { name: "counting" },
          },
          {
            text: "รายการตรวจนับทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/selling/selling-property-list/:id",
      name: "inselling",
      component: () => import("@/views/registration/selling-property-list.vue"),
      meta: {
        navActiveLink: "selling",
        breadcrumb: [
          {
            text: "ตรวจสอบทรัพย์สิน",
          },
          {
            text: "ทะเบียนจำหน่ายทรัพย์สิน",
            to: { name: "selling" },
          },
          {
            text: "จำหน่ายทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    //ทรัพย์สินสิน

    //profile

    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/profile.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    //profile

    {
      path: "/material-group",
      name: "material-group",
      component: () => import("@/views/list/pusduhlak.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "กลุ่มวัสดุ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/material-group/material-group-list",
      name: "inpusduhlak",
      props: true,
      component: () => import("@/views/list/inpusduhlak.vue"),
      meta: {
        navActiveLink: "material-group",
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "กลุ่มวัสด",
            to: { name: "material-group" },
          },
          {
            text: "รายการวัสดุ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/workgroup",
      name: "workgroup",
      component: () => import("@/views/list/workgroup.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "กลุ่มงาน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/workgroup/workgroup-list",
      name: "inworkgroup",
      props: true,
      component: () => import("@/views/list/inworkgroup.vue"),
      meta: {
        navActiveLink: "workgroup",
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "กลุ่มงาน",
            to: { name: "workgroup" },
          },
          {
            text: "รายการกลุ่มงาน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/equipment-group",
      name: "equipment",
      component: () => import("@/views/list/equipment.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "กลุ่มทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/equipment-group/equipment-group-list",
      name: "inequipment",
      props: true,
      component: () => import("@/views/list/inequipment.vue"),
      meta: {
        navActiveLink: "equipment",
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "กลุ่มทรัพย์สิน",
            to: { name: "equipment" },
          },
          {
            text: "รายการทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/material-control",
      name: "majorwasadu",
      component: () => import("@/views/list/majorwasadu.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "ทะเบียนคุมวัสดุ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/unittype",
      name: "unittype",
      component: () => import("@/views/list/unittype.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "หน่วยนับ",
            active: true,
          },
        ],
      },
    },
    {
      path: "/equipment-control",
      name: "majorkuruplun",
      component: () => import("@/views/list/majorkuruplun.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/procurement_method",
      name: "procurement_method",
      component: () => import("@/views/list/procurement_method.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "วิธีการจัดหา",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/money_type",
      name: "money_type",
      component: () => import("@/views/list/money_type.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "ประเภทเงิน",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/proof_type",
      name: "proof_type",
      component: () => import("@/views/list/proof_type.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "ประเภทหลักฐาน",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/seller",
      name: "seller",
      component: () => import("@/views/list/seller.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ผู้ขาย",
          },
          {
            text: "ประเภทเงิน",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/views/list/user.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "ผู้ใช้งาน",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },

    {
      path: "/position",
      name: "position",
      component: () => import("@/views/list/position.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "ตั้งค่าตำแหน่ง",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/import",
      name: "import",
      component: () => import("@/views/list/import.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าข้อมูล",
          },
          {
            text: "Import",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/font",
      name: "font",
      component: () => import("@/views/list/fontsetting.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ตั้งค่าฟอนต์",
          },
          {
            text: "ฟอนต์",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },

    {
      path: "/second-page",
      name: "second-page",
      component: () => import("@/views/SecondPage.vue"),
      meta: {
        pageTitle: "Second Page",
        breadcrumb: [
          {
            text: "Second Page",
            active: true,
          },
        ],
      },
    },
    //report
    {
      path: "/material_group_report",
      name: "material_group_report",
      component: () => import("@/views/report/material_group_report.vue"),
      meta: {
        breadcrumb: [
          {
            text: "รายงาน",
          },
          {
            text: "รายงานกลุ่มวัสดุ",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/material_work_group_report",
      name: "material_work_group_report",
      component: () => import("@/views/report/material_work_group_report.vue"),
      meta: {
        breadcrumb: [
          {
            text: "รายงาน",
          },
          {
            text: "รายงานกลุ่มงานวัสดุ",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/material_group_report_anyone",
      name: "material_group_report_anyone",
      component: () => import("@/views/report/material_group_report_anyone.vue"),
      meta: {
        breadcrumb: [
          {
            text: "รายงาน",
          },
          {
            text: "รายงานวัสดุรายตัว",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/asset_group_report",
      name: "asset_group_report",
      component: () => import("@/views/report/asset_group_report.vue"),
      meta: {
        breadcrumb: [
          {
            text: "รายงาน",
          },
          {
            text: "รายงานกลุ่มทรัพย์สิน",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    {
      path: "/property_group_report",
      name: "property_group_report",
      component: () => import("@/views/report/property_group_report.vue"),
      meta: {
        breadcrumb: [
          {
            text: "รายงาน",
          },
          {
            text: "รายงานกลุ่มงานทรัพย์สิน",
            active: true,
          },
        ],
      },
      pageTitle: "User Setting",
      permission: "admin",
    },
    // {
    //   path: '/preview',
    //   name: 'preview',
    //   component: () => import('@/views/Consuma/output/preview.vue'),
    //   meta: {
    //     breadcrumb: [
    //       {
    //         text: 'รายการ',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/usageHistory",
      name: "usageHistory",
      component: () => import("@/views/usageHistory/usageHistory.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ประวัติการใช้งาน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/controlregistration",
      name: "controlregistration",
      component: () => import("@/views/controlregistration/controlregistration.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            active: true,
          },
        ],
      },
    },
    {
      path: "/controlregistrationALL",
      name: "controlregistrationall",
      component: () => import("@/views/controlregistration/controlregistrationALL.vue"),
      meta: {
        breadcrumb: [
          {
            text: "ทะเบียนคุมทรัพย์สินทั้งหมด",
            active: true,
          },
        ],
      },
    },
    {
      path: "/controlregistrationALL/controlregistrationview/:id",
      name: "controlregistrationview",
      component: () => import("@/views/controlregistration/controlregistrationView.vue"),
      meta: {
        navActiveLink: "controlregistrationall",
        breadcrumb: [
          {
            text: "ทะเบียนคุมทรัพย์สิน",
          },
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            active: true,
          },
        ],
      },
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
